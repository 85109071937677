<template>
  <div class="music">
      <MusicTopNavBar></MusicTopNavBar>
    <!-- 头部组件 -->
<!--    <Top></Top>-->
    <!-- 主体组件 -->
    <index style="width: 100%!important;"></index>
  </div>
</template>

<script>
// import Top from '../components/music/Top.vue'
import MusicTopNavBar from '../components/layout/MusicTopNavBar'
import index from '../components/music/Index.vue'
export default {
  // 注册组件
  components: {
    // Top, // top:top
    MusicTopNavBar,
    index// index:index
  }
}
</script>

<style scoped>
  .music {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: url("https://tcefrep.oss-cn-beijing.aliyuncs.com/blog/image/music-bg2.jpeg") center center /
    cover no-repeat;
    background-color: #49b1f5;
    background-attachment: fixed;
    text-align: center;
    color: #fff !important;
    animation: header-effect 1s !important;
  }
</style>
